import repository from './repository'

const resource = 'pt-cash-voucher-details'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  getByPtCash (ptCashVoucherId) {
    return repository.get(`${resource}/${ptCashVoucherId}/get-by-pt-cash`)
  },
}
