<template lang="pug">
  v-container
    v-row
      v-col(cols="4")
        v-toolbar(
          color="yellow darken-3"
          dense
          flat
          dark
        )
          span Edit
          v-spacer
          v-btn(
            text
            x-small
            dark
            @click="getPtCashVoucher()"
          )
            v-icon mdi-recycle
        v-container
          v-row
            v-col(cols="12")
              v-text-field(
                v-if="!changePayTo"
                label="Pay to"
                :disabled="!payTo"
                :value="payToEntity.name"
                readonly
                dense
              )
                template(v-slot:prepend)
                  span.primary--text {{ payToEntity.id }}
                template(v-slot:append)
                  base-tooltip(
                    dark
                    bottom
                    tooltip="Change Pay To"
                    text
                    color="yellow darken-2"
                    x-small
                    @click="changePayTo = true"
                  )
                    v-icon mdi-pencil
              v-autocomplete(
                v-else
                label="Pay To"
                v-model="payTo"
                :search-input.sync="payToSearch"
                item-value="id"
                item-text="name"
                :items="voucherPayToList"
                :loading="voucherPayToGetting"
                clearable
                no-filter
                dense
              )
                template(v-slot:prepend)
                  base-tooltip(
                    dark
                    bottom
                    tooltip="Cancel"
                    text
                    color="error"
                    x-small
                    @click="changePayTo = false"
                  )
                    v-icon mdi-close
                template(v-slot:selection="{ item }")
                  span.primary--text.mr-2 {{ item.id }}
                  small.blue-grey--text {{ item.name }} ({{ item.type }})
                template(v-slot:item="{ item }")
                  span.primary--text.mr-2 {{ item.id }}
                  small.blue-grey--text {{ item.name }} ({{ item.type }})
            v-col(cols="6")
              v-text-field(
                label="PT Cash No"
                disabled
                dense
                :value="ptCashVoucherNumber"
              )
            v-col(cols="6")
              v-text-field(
                label="Date"
                type="date"
                dense
                v-model="date"
              )
            v-col(cols="12")
              v-text-field(
                label="Address"
                v-model="address"
                dense
              )
            v-col(cols="6")
              v-btn(
                block
                dense
                dark
                color="yellow darken-3"
                @click="updatePtCashData"
                :loading="ptCashVuocherUpdatePosting"
              )
                span Update
            v-col(cols="6")
              v-btn(
                block
                dense
                dark
                color="error"
                @click="$router.go(-1)"
              )
                span Cancel
      v-col(cols="8")
        v-toolbar(
          color="purple"
          dense
          flat
          dark
        )
          span Details
          v-spacer
          v-btn(
            color="green"
            dark
            small
            dense
            @click="createPtCashDetailDialog = true"
          )
            span Add
        v-simple-table
          template(v-slot:default)
            thead
              tr
                th Account Code
                th Description
                th.text-right Amount
            tbody
              tr(v-for="(item, index) in ptCashDetailList" :key="index")
                td
                  v-btn(
                    color="yellow darken-3"
                    text
                    x-small
                    @click="editPtCashDetail(item)"
                  )
                    v-icon mdi-pencil
                  span {{ item.account_code.account_code }}
                td {{ item.description }}
                td.text-right {{ item.amount.phpFormat() }}
    edit-detail(
      v-model="editPtCashDetailDialog"
      :edit.sync="editPtCashDetailData"
      :account-codes="voucherAccountCodeList"
    )
    create-detail(
      v-model="createPtCashDetailDialog"
      :account-codes="voucherAccountCodeList"
    )
</template>
<script>
import voucherRepository from '@/repositories/voucher.repository'
import ptCashVoucherDetailRepository from '@/repositories/pt-cash-voucher-detail.repository'
import ptCashVoucherRepository from '@/repositories/pt-cash-voucher.repository'
import { requestVars } from '@/libs/api-helper.extra'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'

const [voucherPayToVars, voucherPayToVarNames] = requestVars({ identifier: 'voucher-pay-to' })
const [ptCashVoucherEditVars, ptCashVoucherEditVarNames] = requestVars({ identifier: 'pt-cash-voucher', hasData: false })
const [ptCashVoucherUpdateVars, ptCashVoucherUpdateVarNames] = requestVars({ identifier: 'pt-cash-vuocher-update', request: 'post', hasData: false })
const [ptCashDetailVars, ptCashDetailVarNames] = requestVars({ identifier: 'pt-cash-detail' })
const [voucherAccountCodeVars, voucherAccountCodeVarNames] = requestVars({ identifier: 'voucher-account-code' })

const voucherPayToHandler = new VueRequestHandler(null, voucherPayToVarNames)
const ptCashVoucherEditHandler = new VueRequestHandler(null, ptCashVoucherEditVarNames)
const ptCashVoucherUpdateHandler = new VueRequestHandler(null, ptCashVoucherUpdateVarNames)
const ptCashDetailHandler = new VueRequestHandler(null, ptCashDetailVarNames)
const voucherAccountCodeHandler = new VueRequestHandler(null, voucherAccountCodeVarNames)

const inputVars = () => ({
  id: null,
  payTo: null,
  address: null,
  date: null,
  details: [],
})

export default {
  name: 'PtCashVoucherEdit',
  components: {
    createDetail: () => import('./CreatePtCashVoucherDetail'),
    editDetail: () => import('./EditPtCashVoucherDetail'),
  },
  data() {
    return {
      ...voucherPayToVars,
      ...ptCashVoucherEditVars,
      ...ptCashVoucherUpdateVars,
      ...ptCashDetailVars,
      ...voucherAccountCodeVars,
      ...inputVars(),
      payToSearch: 1,
      payToEntity: {},
      ptCashVoucherNumber: null,
      changePayTo: false,
      createPtCashDetailDialog: false,
      editPtCashDetailDialog: false,
      editPtCashDetailData: {},
    }
  },
  created() {
    this.getPayToVars()
    this.getPtCashVoucher()
    this.initWebsockets()
    this.getPtCashVoucherDetails()
    this.getVoucherAccountCodes()
  },
  computed: {
    payToType() {
      if (!this.payTo) return null
      if (this.payTo === this.payToEntity?.id) return this.payToEntity.type
      const payTo = this.voucherPayToList.find(item => item.id === this.payTo)
      if (!payTo) return null
      return payTo.type
    },
    ptCashVoucherId() {
      return this.$route.params.ptCashVoucherId
    },
    voucherId() {
      return this.$route.params.voucherId
    },
  },
  watch: {
    ptCashVoucherId() {
      this.getPtCashVoucher()
    },
    payToSearch(val) {
      this.getPayToVars()
    },
    changePayTo(change) {
      if (change) {
        this.payTo = this.voucherPayToList.first().id || null
        return
      }
      this.payTo = this.payToEntity.id
    },
  },
  methods: {
    getVoucherAccountCodes() {
      const handler = voucherAccountCodeHandler
      const repository = voucherRepository.getAccountCodes
      handler.setVue(this)
      handler.execute(repository, [this.voucherId])
    },
    editPtCashDetail(item) {
      this.editPtCashDetailData = item
      this.editPtCashDetailDialog = true
    },
    initWebsockets() {
      const { echo } = this.$store.state.websocket
      const dbUpdateEvent = ({ data, model }) => {
        if (model === 'PtCashVoucher') {
          this.populateInputs({ data })
          return
        }
        if (model === 'PtCashVoucherDetail') {
          this.getVoucherAccountCodes()
          this.updatePtCashVoucherDetail(data)
        }
      }
      const dbStoreEvent = ({ data, model }) => {
        if (model === 'PtCashVoucherDetail') {
          this.getVoucherAccountCodes()
          this.ptCashDetailList.push(data)
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', dbStoreEvent)
        .listen('DBUpdateEvent', dbUpdateEvent)
    },
    updatePtCashVoucherDetail(data) {
      this.ptCashDetailList = this.ptCashDetailList.map(item => {
        if (item.id === data.id) return { ...data }
        return { ...item }
      })
    },
    getPtCashVoucherDetails() {
      const handler = ptCashDetailHandler
      const repository = ptCashVoucherDetailRepository.getByPtCash
      handler.setVue(this)
      handler.execute(repository, [this.ptCashVoucherId])
    },
    async updatePtCashData() {
      const handler = ptCashVoucherUpdateHandler
      const repository = ptCashVoucherRepository.update
      const { id, ...data } = this.getInputData()
      handler.setVue(this)
      await handler.execute(repository, [id, data])
      this.$router.push(`/vouchers/${this.$route.params.voucherId}/petty-cash`)
    },
    getPayToVars() {
      const handler = voucherPayToHandler
      const repository = voucherRepository.payToList
      const params = {
        search: this.payToSearch,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getPtCashVoucher() {
      const handler = ptCashVoucherEditHandler
      const repository = ptCashVoucherRepository.edit
      handler.setVue(this)
      handler.execute(repository, [this.ptCashVoucherId], this.populateInputs)
    },
    getInputData() {
      return Object.keys(inputVars())
        .concat(['payToType'])
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
    populateInputs({ data }) {
      Object.keys(inputVars())
        .concat(['ptCashVoucherNumber'])
        .forEach(key => {
          const snakeKey = key.camelToSnakeCase()
          if (key === 'payTo') {
            this.payTo = data[snakeKey].id
            this.payToEntity = data[snakeKey]
            return
          }
          this[key] = data[snakeKey]
        })
    },
  },
}
</script>
